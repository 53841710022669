import { useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { getSummary } from '../../services/apiService';
import SummaryUx from './SummaryUx'
import { LoadingBox } from '../structure/LoadingBox'

export default function SummaryContainer() {
  const [summary, setSummary] = useState(undefined);

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      getSummary(getAccessTokenSilently).then(setSummary)
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  return isAuthenticated && (summary ? <SummaryUx summary={summary} /> : <LoadingBox />)
}
