import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { CircularProgress } from "@mui/material";
import { useGoogleLogin, hasGrantedAllScopesGoogle } from "@react-oauth/google";
import { postUpdateWeight } from "../../services/apiService";

const scope = "https://www.googleapis.com/auth/fitness.body.read";

const GoogleLoginButton = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [saved, setSaved] = useState(false);
  const [syncing, setSyncing] = useState(false);

  const handleSync = () => {
    setSyncing(true);
    handleGoogleLogin();
  };
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      console.log(codeResponse);
      console.log(codeResponse.access_token);
      const hasAccess = hasGrantedAllScopesGoogle(codeResponse, scope);
      console.log(hasAccess);
      await postUpdateWeight(getAccessTokenSilently, codeResponse.access_token);
      setSaved(true);
      setSyncing(false);
    },
    scope,
  });

  return (
    <Paper sx={{ textAlign: "center", mx: 1, my: 1, px: 1, py: 1 }}>
      <Button
        disabled={syncing}
        variant="contained"
        type="submit"
        onClick={handleSync}
      >
        Sync with Google
      </Button>
      {syncing && (
        <>
          <p>sycing weight data...</p>
          <CircularProgress />
        </>
      )}
      {saved && <Navigate to="/Summary" />}
    </Paper>
  );

};

export default GoogleLoginButton;
