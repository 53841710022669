import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
const pages = ['Summary', 'Calendar', 'Today', 'Week', 'Locations', 'Holidays', 'CheckIn', 'Sync'];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const { user, isAuthenticated, loginWithRedirect, logout, isLoading } =
    useAuth0();

  function LoginLogout() {
    if (isLoading) {
      return <></>;
    }
    if (!isAuthenticated) {
      return (
        <Button
          onClick={() => loginWithRedirect()}
          sx={{ my: 2, color: 'yellow', display: 'block' }}
        >
          Login
        </Button>
      );
    }
    return (
      <Button
        onClick={() => logout({ returnTo: window.location.origin })}
        sx={{ my: 2, color: 'white', display: 'block' }}
      >
        Log Out
      </Button>
    );
  }

  function LoginLogoutMenu() {
    const command = isAuthenticated
      ? {
          text: 'Logout',
          func: () => logout({ returnTo: window.location.origin }),
        }
      : { text: 'Login', func: loginWithRedirect };
    return (
      <MenuItem onClick={() => command.func()}>
        <Typography textAlign="center">{command.text}</Typography>
      </MenuItem>
    );
  }

  function getAvatar() {
    if (isAuthenticated) {
      return <Avatar src={user.picture} alt={user.name} />;
    }
    return <Avatar>?</Avatar>;
  }
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
              Markysoft
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {isAuthenticated && pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                <Link
                  to={'/' + page}
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  {page}
                </Link>
              </Button>
            ))}
            <LoginLogout></LoginLogout>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {isAuthenticated && pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">
                    <Link
                      to={'/' + page}
                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                      {page}
                    </Link>
                  </Typography>
                </MenuItem>
              ))}
              <LoginLogoutMenu />
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            Almanac
          </Typography>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton sx={{ p: 0 }}>{getAvatar()}</IconButton>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
