import { setCache, getCache } from './storageCache';
import { apiUrl } from '../lib/config'
import { toDateId } from '../lib/timeUtils'

export async function getDays(getAccessTokenSilently, year) {
  return await getFromApi(getAccessTokenSilently, 'days', token => daysRequest(token, year))
}

export async function getLocations(getAccessTokenSilently, year) {
  return await getFromApi(getAccessTokenSilently, `locations${year}`, token => locationsRequest(token, year))
}

export async function getBankHolidays(getAccessTokenSilently) {
  return await getFromApi(getAccessTokenSilently, 'bank-holidays', bankHolidaysRequest)
}

export async function getWeek(getAccessTokenSilently) {
  return await getFromApi(getAccessTokenSilently, 'week', weekRequest)
}

export async function getToday(getAccessTokenSilently) {
  return await getFromApi(getAccessTokenSilently, 'today', todayRequest)
}

export async function getSummary(getAccessTokenSilently) {
  return await getFromApi(getAccessTokenSilently, 'summary', summaryRequest)
}

export async function postUpdateRunning(getAccessTokenSilently, authToken) {
  return await postToApi(getAccessTokenSilently, (token) => runningPost(token, authToken))
}

export async function postUpdateWeight(getAccessTokenSilently, accessToken) {
  return await postToApi(getAccessTokenSilently, (token) =>
    weightPost(token, accessToken)
  );
}

async function bankHolidaysRequest(token) {
  return await fetch(`${apiUrl}get-bank-holidays`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

async function locationsRequest(token, year) {
  return await fetch(`${apiUrl}get-locations?year=${year}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

async function weekRequest(token) {
  return await fetch(`${apiUrl}get-week-ahead`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

async function daysRequest(token, year) {
  return await fetch(`${apiUrl}get-days?year=${year}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

async function summaryRequest(token) {
  return await fetch(`${apiUrl}get-summary`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

async function runningPost(token, authToken) {
  return await fetch(`${apiUrl}update-running`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ authToken })
  });
}

async function weightPost(token, accessToken) {
  return await fetch(`${apiUrl}update-weight`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ accessToken }),
  });
}

async function todayRequest(token) {
  const today = new Date()
  const id = toDateId(today)
  const response = await fetch(`${apiUrl}get-day?id=${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response
}

export async function getFromApi(getAccessTokenSilently, cacheName, reqFunc) {
  const cachedVal = getCache(cacheName);
  if (cachedVal) {
    console.log(`getting ${cacheName} from cache`);
    return cachedVal
  } else {
    const token = await getAccessTokenSilently();
    try {
      const response = await reqFunc(token)
      const json = await response.json();
      console.log(`setting ${cacheName} in cache`);
      setCache(cacheName, json)
      return json
    } catch (error) {
      console.log('error', error);
    }
  }
}

export async function postToApi(getAccessTokenSilently, postFunc) {
  const token = await getAccessTokenSilently();
  try {
    const response = await postFunc(token)
    const json = await response.json();
    return json
  } catch (error) {
    console.log('error', error);
  }

}