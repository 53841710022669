import React from 'react';
import Box from '@mui/material/Box';
import dateFormat from 'dateformat';
import Paper from '@mui/material/Paper';
import RoadIcon from '@mui/icons-material/LocationCity';
import HillIcon from '@mui/icons-material/Park';
import NoRun from '@mui/icons-material/Spa';
import Typography from '@mui/material/Typography';

function reduceRunning(running) {
  if (!running || running.length === 0) return
  const runs = running.reduce((p, c) => {
    if (!p) {
      return { ...c }
    }
    p.time += c.time
    p.distance += c.distance
    p.type = c.type === 'Hill' ? c.type : p.type
    if (c.time > p.time) {
      p.avgHr = c.avgHr
      p.maxHr = c.maxHr
      p.id = c.id
    }
    return p
  })

  return runs
}

function RunningStats({ running }) {
  if (!running) {
    return <Box><NoRun /></Box>
  }
  return (

    <a href={`https://www.strava.com/activities/${running.stravaId}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <Box sx={{
        fontSize: '0.7em',
        textAlign: 'center'
      }}>
        {/* { running.timeOfDay} <br/> */}
        <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: '1.8em' }}>{running.milesString}</Typography>
        {running.type === 'Hill' ? <HillIcon /> : <RoadIcon />} <br />
        <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: '1.2em', }}>{running.timeString}</Typography>
        <Typography sx={{ fontWeightx: (theme) => theme.typography.fontWeightBold, fontSize: '1em', }}>({running.paceString})</Typography>
        <Typography sx={{ fontWeightx: (theme) => theme.typography.fontWeightBold, fontSize: '1em', }}>HR {running.avgHr}</Typography>
      </Box>
    </a>
  )
}

export function RunningTile({ day }) {
  const dateTitle = dateFormat(day.date, 'dd/mm');
  const running = reduceRunning(day.running)
  return (
    <Paper sx={{
      justifyContent: 'center',
      width: '3.7em',
      height: '8.5em',
      mx: 1,
      my: 1,
      display: 'flex',
      flexWrap: 'wrap',
      //color: (theme) => theme.palette.grey[200],
      backgroundColor: (theme) => running ? theme.palette.success.light: theme.palette.primary.light,
    }}>
      <Box sx={{
        fontWeight: (theme) => running
          ? theme.typography.fontWeightBold
          : theme.typography.fontWeightRegular,
      }}>{dateTitle}</Box>
      <RunningStats running={running} />
    </Paper>
  );
}
