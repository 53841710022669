import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { GoogleOAuthProvider } from "@react-oauth/google";
import StravaSync from './StravaSync';
import GoogleLoginButton from './GoogleLoginButton';

export default function Sync() {


    return (
      <Container
        maxWidth="sm"
        component="main"
        sx={{
          pt: 6,
          pb: 4,
          display: "flex",
          flexWrap: "wrap",
          p: 1,
          m: 1,
          justifyContent: "center",
        }}
      >
        <Grid container spacing={4} alignItems="stretch">
          <Grid item xs={12} sm={12} md={12} sx={12}>
            
            <StravaSync />

            <GoogleOAuthProvider clientId="178182880181-gcghv3cu8i7n15nbfa39l45pqt3j5t6l.apps.googleusercontent.com">
              <GoogleLoginButton />
            </GoogleOAuthProvider>
          </Grid>
        </Grid>
      </Container>
    );
}