import Container from '@mui/material/Container';
import { Copyright } from './Copyright';

export default function Footer() {
  return (
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 2,
          py: [3, 2],
        }}
      >
        <Copyright sx={{ mt: 2 }} />
      </Container>
  );
}