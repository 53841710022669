import dateFormat from 'dateformat';

export function toHoursMins(date) {
    try {
        return dateFormat(new Date(date), 'HH:MM')
    } catch (ex) {
        console.error('invalid date', date)
    }
}


export function toDateId(date) {
    const midnight = date
    midnight.setUTCHours(0, 0, 0, 0)
    return String(Math.round(midnight.getTime()  / 100000))
}