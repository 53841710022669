import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import dateFormat from 'dateformat';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';



export function HolidayTile({ day }) {
  return (
    <Grid  
    item
    key={day.dateTime}
    xs={12}
    sm={6}
    md={6}>
      <Card >
        <CardHeader
          title={dateFormat(day.date, 'ddd d mmmm')}

          subheader={day.notes}
          titleTypographyProps={{ align: 'center' }}
          subheaderTypographyProps={{
            align: 'center',
          }}
          sx={{
            backgroundColor: (theme) => {
              return theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[700];
            },
          }}
        />
        <CardContent
          sx={{

          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minheight: '8em' 
            }}
          >
            <Typography component="h2" variant="h4" align="center">
              {day.title}
            </Typography>
          </Box>

        </CardContent>
      </Card>
    </Grid>
  );
}
