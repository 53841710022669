import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import dateFormat from 'dateformat';

function Location({ location }) {

  return (
    <a key={location.id} href={`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${location.placeId}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none", color: "inherit" }}
    >

      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box >
          <Typography component="h2" variant="subtitle" align="center">
            {location.name}
          </Typography>
        </Box>
        <Box >
          <Typography component="h3" variant="subtitle" align="center">
            {dateFormat(location.arrived, 'HH:MM')} - {location.duration} mins
          </Typography>
        </Box>
        <Box sx = {{ pb: '1em'}}>
          <Typography component="h4" variant="caption" align="center">
            {location.address}
          </Typography>
        </Box>
      </Box>


    </a>
  )
}

export function LocationTile({ day }) {
  return (
    <Grid
      item
      key={day.dayId}
      xs={12}
      sm={12}
      
      md={12}
  
      >
      <Card >
        <CardHeader
          title={dateFormat(Number(day.dayId) * 100000, 'ddd d mmmm yyyy')}
          titleTypographyProps={{ align: 'center' }}
          subheaderTypographyProps={{
            align: 'center',
          }}
          sx={{
            backgroundColor: (theme) => {
              return theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[700];
            },
          }}
        />
        <CardContent
          sx={{

          }}
        >
          {day.locations.map(location => <Location location={location} key={location.id} />)}
        </CardContent>
      </Card>
    </Grid>
  );
}
