import React, { useEffect, useState } from 'react';
import HolidaysUx from './HolidaysUx';
import { useAuth0 } from '@auth0/auth0-react';

import { getBankHolidays } from '../../services/apiService';
import { LoadingBox } from '../structure/LoadingBox'

export default function HolidaysContainer() {
  const [holidays, setHolidays] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      getBankHolidays(getAccessTokenSilently).then(setHolidays).then(() => setLoading(false))
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  return (
    isAuthenticated && (
      loading ?
        <LoadingBox /> :
        <HolidaysUx holidays={holidays} />
    )
  );
}
