import React from 'react';
import Box from '@mui/material/Box';
import dateFormat from 'dateformat';
import { toHoursMins } from '../../lib/timeUtils'
import Paper from '@mui/material/Paper';

function phaseToIcon(phase) {
  const moonPhase = [
    'new',
    'waxing-crescent-1',
    'waxing-crescent-2',
    'waxing-crescent-3',
    'waxing-crescent-4',
    'waxing-crescent-5',
    'waxing-crescent-6',
    'first-quarter',
    'waxing-gibbous-1',
    'waxing-gibbous-2',
    'waxing-gibbous-3',
    'waxing-gibbous-4',
    'waxing-gibbous-5',
    'waxing-gibbous-6',
    'full',
    'waning-gibbous-1',
    'waning-gibbous-2',
    'waning-gibbous-3',
    'waning-gibbous-4',
    'waning-gibbous-5',
    'waning-gibbous-6',
    'third-quarter',
    'waning-crescent-1',
    'waning-crescent-2',
    'waning-crescent-3',
    'waning-crescent-4',
    'waning-crescent-5',
    'waning-crescent-6',
  ]
  return `wi wi-moon-${moonPhase[Math.round(phase * 27)]}`
}

function compare(a, b) {
  if (a.time < b.time) {
    return -1;
  }
  if (a.time > b.time) {
    return 1;
  }
  return 0;
}

function riseSetMessages(moon) {
  const times = [
    { type: 'R', time: moon.rise },
    { type: 'S', time: moon.set }
  ]
  return times.sort(compare).map(t => t.time ? `${t.type} ${toHoursMins(t.time)}` : '' )
}

export function MoonTile({ day }) {
  const dateTitle = dateFormat(day.date, 'dd/mm');
  const messages  = riseSetMessages(day.moon)
  return (
    <Paper sx={{
      justifyContent: 'center',
      width: '3.7em',
      // height: '5.2em',
      mx: 1,
      my: 1,
      display: 'flex',
      flexWrap: 'wrap',
      color: (theme) => theme.palette.grey[300],
      backgroundColor: (theme) => theme.palette.grey[900]
    }}>
      <Box sx={{
        fontWeight: (theme) => theme.typography.fontWeightBold,
      }}>{dateTitle}</Box>
      <Box sx={{
        fontSize: "2.8em",
        transform: `rotate(${90}${(day.moon.angle * 360) / Math.PI}deg)`
      }}>
        <i className={phaseToIcon(day.moon.phase)} ></i>
      </Box>
      <Box sx={{
        fontSize: '0.7em',
      }}>
        {messages.map(m => <span key={m}>{m}<br/></span>)}
      </Box>
    </Paper>
  );
}
