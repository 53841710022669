import Container from '@mui/material/Container';

export function FlexWrapContainer(props) {
  return (
    <Container
      maxWidth="sm"
      component="main"
      sx={{
        pt: 6,
        pb: 4,
        display: 'flex',
        flexWrap: 'wrap',
        p: 1,
        m: 1, 
        justifyContent: 'flex-start',
      }}
    >
      {props.children}
    </Container>
  );
}
