const maxCache = 1;
export function getCache(key) {
  const strVal = window.localStorage.getItem(key);
  const cacheVal = strVal !== null ? JSON.parse(strVal) : undefined;
  return cacheVal && cacheVal.expires > Date.now() ? cacheVal.value : undefined;
}

export function setCache(key, value) {
  const expires =Date.now() + (maxCache * 1000 * 60);
  window.localStorage.setItem(key, JSON.stringify({value, expires}));
}
