import React from 'react';
import Box from '@mui/material/Box';
import dateFormat from 'dateformat';
import Paper from '@mui/material/Paper';

function toTideText(times) {
  return times.map((time) => `${time.type === 'High' ? 'H' : 'L'} ${time.time}`)
}


export function TideTile({ day }) {
  const dateTitle = dateFormat(day.date, 'dd/mm');
  const messages  = toTideText(day.tides)
  return (
    <Paper sx={{
      justifyContent: 'center',
      width: '3.7em',
      // height: '8.2em',
      mx: 1,
      my: 1,
      display: 'flex',
      flexWrap: 'wrap',
      color: (theme) => theme.palette.grey[300],
      backgroundColor: (theme) => theme.palette.grey[900]
    }}>
      <Box sx={{
        fontWeight: (theme) => theme.typography.fontWeightBold,
      }}>{dateTitle}</Box>
      <Box sx={{
        fontSize: '0.7em',
      }}>
        {messages.map(m => <span key={m}>{m}<br/></span>)}
      </Box>
    </Paper>
  );
}
