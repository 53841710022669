import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import ResponsiveAppBar from './components/structure/ResponsiveAppBar';
import MainContent from './components/structure/MainContent';
import Footer from './components/structure/Footer';
import { Auth0Provider } from '@auth0/auth0-react';

function App() {
  const [redirected, setRedirected] = useState(false);
  return (
    <Auth0Provider
      domain="dev-gllnszjy.us.auth0.com"
      clientId="fRBTmhQPpwP4Ny78WJu90hjyIgyha1Mq"
      audience="https://traktalmanac"
      redirectUri={window.location.origin}
      onRedirectCallback={() => setRedirected(true)}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <Router>
        <GlobalStyles
          styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }}
        />
        <CssBaseline />
          <ResponsiveAppBar />
          <MainContent redirected={redirected} />
          <Footer />
      </Router>
    </Auth0Provider>
  );
}

export default App;
