import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { getWeek } from '../../services/apiService';
import WeekUx from './WeekUx'

export default function WeekContainer() {
  const [week, setWeek] = useState([]);
  const [view, setView] = useState('health');
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      getWeek(getAccessTokenSilently).then(setWeek)
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  return (
    isAuthenticated && (<WeekUx week={week} view={view} setView={setView} />)
  );
}
