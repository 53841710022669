import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

export default function ScoreCard(props) {
  const deets = props.deets;
  return (
    <Card>
      <CardHeader
        title={deets.title}
        action={deets.icon}
        subheader={deets.subheader}
        titleTypographyProps={{ align: 'center' }}
        subheaderTypographyProps={{
          align: 'center',
        }}
        sx={{
          backgroundColor: (theme) => {
            return theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[700];
          },
        }}
      />
      <CardContent
        sx={{
          color: (theme) => {
            if (deets.good) {
              return theme.palette.success.main;
            }
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'baseline',
            mb: 2,
          }}
        >
          <Typography component="h2" variant="h3" align="center">
            {deets.content}
          </Typography>
        </Box>
        <ul>
          {deets.description.map((line) => (
            <Typography
              component="li"
              variant="subtitle1"
              align="center"
              key={line}
            >
              {line}
            </Typography>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
}
