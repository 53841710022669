import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import Chip from '@mui/material/Chip';
import { FlexWrapContainer } from '../structure/FlexWrapContainer';

import { LocationTile } from './LocationTile';

function selectOn(year, match) {
  return year === match ? 'outlined' : ''
}

function SelectChip({ label, type, year, onClick }) {
  return <Chip sx={{ m: 1 }} label={label} variant={selectOn(year, type)} onClick={onClick} />
}

export default function LocationsUx({ days, year, setYear }) {
  return (
    <>
      <FlexWrapContainer>
        <SelectChip label="2021" type="2021" year={year} onClick={() => setYear('2021')} />
        <SelectChip label="2022" type="2022" year={year} onClick={() => setYear('2022')} />
        <SelectChip label="All" type="All" year={year} onClick={() => setYear('')} />
      </FlexWrapContainer>
      <Container maxWidth="sm" component="main" sx={{
        pt: 6,
        pb: 4
      }}>
        <Grid container spacing={4} alignItems="top">
          {days.map(day => <LocationTile day={day} key={day.dayId}></LocationTile>)}
        </Grid>
      </Container>
    </>
  );
}
