import Box from '@mui/material/Box';
import dateFormat from 'dateformat';
import Paper from '@mui/material/Paper';

function toTideText(times) {
  return times.map((time) => dateFormat(time, 'HH:MM'))
}


export function WeekTile({ day }) {
  const dateTitle = dateFormat(new Date(Number(day.date) * 100000), 'ddd');
  const messages = toTideText(day.tides)

  /*
   `${today.weather.main} (${today.weather.description})`,
      `Chance of rain ${Math.round(100 * today.weather.chanceOfRain)}%`,
      `Temperature ${Math.round(today.weather.temp.day)}°C`, 
      `max: ${Math.round(today.weather.temp.max)}°C, min: ${Math.round(today.weather.temp.min)}°C`,
      `Wind ${Math.round(today.weather.wind.windSpeed)} (max ${Math.round(today.weather.wind.windSpeed + today.weather.wind.maxWindSpeed)}) mph`
  

  */
  return (
    <Paper sx={{
      justifyContent: 'top',
      width: '5.8em',
      // height: '8.2em',
      mx: 1,
      my: 1,
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      color: (theme) => theme.palette.grey[300],
      backgroundColor: (theme) => theme.palette.grey[900]
    }}>
      <Box sx={{
        fontWeight: (theme) => theme.typography.fontWeightBold,
      }}>{dateTitle}</Box>
      <Box sx={{
        fontSize: '0.8em',  my: 1,
      }}>
        
        <Box sx={{
        fontWeight: (theme) => theme.typography.fontWeightBold,
      }}>Weather</Box>
        {day.weather.main}<br/>
        ({day.weather.description})<br/>
        Rain? {Math.round(100 * day.weather.chanceOfRain)}%
      </Box>
      <Box sx={{
        fontSize: '0.8em',
      }}>
        {day.weather.temp.max}°C
      </Box>
      <Box sx={{
        my: 1,
        fontSize: '0.7em',
      }}>
        <Box sx={{
        fontWeight: (theme) => theme.typography.fontWeightBold,
      }}>Wind</Box>
       {Math.round(day.weather.wind.speed)}mph <br/>
       max {Math.round(day.weather.wind.speed + day.weather.wind.maxSpeed)}mph
      </Box>
      <Box sx={{
        my: 1,
        fontSize: '0.7em',
      }}>
        <Box sx={{
        fontWeight: (theme) => theme.typography.fontWeightBold,
      }}>Low Tides</Box>
        {messages.map(m => <span key={m}>{m}<br /></span>)}
      </Box>
    </Paper>
  );
}
