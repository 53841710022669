import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useAuth0 } from '@auth0/auth0-react';

import { postUpdateRunning } from '../../services/apiService'

async function getStravaInfo(getAccessTokenSilently) {
    const params = new URLSearchParams(window.location.search)
    const code = params.get('code')
    await postUpdateRunning(getAccessTokenSilently, code)
}

export default function StravaCallback() {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        if (isAuthenticated) {
            getStravaInfo(getAccessTokenSilently)
        }
    }, [isAuthenticated, getAccessTokenSilently])

    return (
        <Typography variant="body2" color="text.secondary" align="center" >
           Strava sync complete
        </Typography>
    );
}
