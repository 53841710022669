import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import SummaryContainer from '../summary/SummaryContainer';
import WeekContainer from '../week/WeekContainer';
import DayCalendar from '../days/DayCalendarContainer';
import LocationsContainer from '../locations/LocationsContainer';
import HolidaysContainer from '../holidays/HolidaysContainer';
import StravaCallback from '../sync/StravaCallback';
import Sync from '../sync/Sync';
import Today from '../Today';
import CheckIn from '../CheckIn';
import { useAuth0 } from '@auth0/auth0-react';
import { Routes, Route } from 'react-router-dom';
import Box from '@mui/material/Box';
import { LoadingBox } from './LoadingBox';
import ErrorBoundary from './ErrorBoundary';

function LoadedContent({ redirected }) {
  

  return (
    <>
      <Box sx={{ mr: 2, display: { xs: "none", md: "block" } }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Almanac
        </Typography>
      </Box>
      <Typography
        variant="h5"
        align="center"
        color="text.secondary"
        component="p"
      >
        Being an account of how well I've looked after myself this year
      </Typography>
      <ErrorBoundary>
        <Routes>
          <Route
            exact
            path="/"
            element={<SummaryContainer redirected={redirected} />}
          />
          <Route
            exact
            path="/summary"
            element={<SummaryContainer redirected={redirected} />}
          />
          <Route exact path="/today" element={<Today />} />
          <Route
            exact
            path="/checkin"
            element={<CheckIn redirected={redirected} />}
          />
          <Route exact path="/sync" element={<Sync />} />
          <Route exact path="/calendar" element={<DayCalendar />} />
          <Route exact path="/week" element={<WeekContainer />} />
          <Route exact path="/locations" element={<LocationsContainer />} />
          <Route exact path="/holidays" element={<HolidaysContainer />} />
          <Route
            exact
            path="/redirect/exchange_token"
            element={<StravaCallback />}
          />
        </Routes>
      </ErrorBoundary>
    </>
  );
}

export default function MainContent({ redirected }) {
  const { isLoading, isAuthenticated } = useAuth0();
  console.log('isLoading', [isLoading, isAuthenticated, redirected]);
  return (
    <Container
      disableGutters
      maxWidth="sm"
      component="main"
      sx={{ pt: 4, pb: 6, justifyContent: 'center' }}
    >
      {isLoading ? <LoadingBox /> : <LoadedContent redirected={redirected} />}
    </Container>
  );
}
