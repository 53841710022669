import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ScoreCard from './ScoreCard';
import DryIcon from '@mui/icons-material/FavoriteTwoTone';
import DrinkIcon from '@mui/icons-material/LocalDrinkTwoTone';
import LowestWeightIcon from '@mui/icons-material/RemoveCircle';
import PlusWeightIcon from '@mui/icons-material/AddCircle';


function ScoreCards({ scoreCards }) {
  return scoreCards.map((deets) => (
    <Grid
      item
      key={deets.title}
      xs={deets.xs}
      sm={deets.sm}
      md={deets.md}
    >
      <ScoreCard deets={deets} />
    </Grid>
  ))
}

function lossOrGain(val) {
  return val < 0 ? 'loss' : 'gain'  
}

function achievementsCard(achievements) {
  return  achievements ? {
    title: 'Achievements',
    content: 'Ranking',
    description: [
      `${achievements.week} this week`,
      `${achievements.month} this month`,
      `${achievements.quarter} this quarter`,
      `${achievements.year} this year`,
      '-',
      `${Math.abs(Math.round(achievements.weekChange * 10) / 10)}kg ${lossOrGain(achievements.weekChange)} since this time last week`,
    ],
    xs: 12,
    sm: 12,
    md: 12  
  } : 
   {
    title: 'No check in today',
    description: ['Enter today\'s details for relative scores'],
    xs: 12,
    sm: 12,
    md: 12  
  }
}

function createScoreCards(summary) {
  return [
    {
      title: 'Days Dry',
      icon: <DryIcon />,
      content: summary.daysDry,
      description: ['Days in which', 'I did not drink'],
      xs: 12,
      sm: 6,
      md: 6,
    },   
    {
      title: 'Days Drinking',
      icon: <DrinkIcon />,
      content: summary.daysDrinking,
      description: ['Days in which', 'I succumbed'],
      xs: 12,
      sm: 6,
      md: 6,
    },
    {
      title: 'Should I drink?',
      content: summary.shouldIDrink,
      good: summary.score > 2,
      description: [
        `${Math.abs(summary.score)} ${summary.score > 0 ? 'more' : 'fewer'} days dry than drinking this year`,
      ],
      xs: 12,
      sm: 12,
      md: 12,
    },
    {
      title: 'Max Weight',
      icon: <PlusWeightIcon />,
      content: summary.weight?.max,
      description: [],
      xs: 12,
      sm: 6,
      md: 6,
    },
    {
      title: 'Minimum weight',
      icon: <LowestWeightIcon />,
      content: summary.weight?.min,
      description: [],
      xs: 12,
      sm: 6,
      md: 6,
    },
    {
      title: 'Current Weight',
      content: summary.weight?.current,
      good: summary.weight.current < summary.weight.max,
      description: [
        `${summary.weight.loss}kg less than max weight`,
      ],
      xs: 12,
      sm: 12,
      md: 12,
    },
    achievementsCard(summary.achievements),
  ];
}

export default function SummaryUx({ summary }) {

  const scoreCards = summary ? createScoreCards(summary) : []
  return (
    <Container maxWidth="sm" component="main" sx={{ pt: 6, pb: 4 }}>
      <Grid container spacing={4} alignItems="center">
        <ScoreCards scoreCards={scoreCards} />
      </Grid>
    </Container>
  )
}
