import { WeekTile } from './WeekTile';

import { FlexWrapContainer } from '../structure/FlexWrapContainer';


export default function WeekUx({ week }) {
  return (
    <>
      <FlexWrapContainer>
        {week.map(day => <WeekTile day={day} key={day.date}></WeekTile>)}
      </FlexWrapContainer>
    </>
  );
}
