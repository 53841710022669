import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { HolidayTile } from './HolidayTile';

export default function HolidaysUx({ holidays }) {
  return (
    <Container maxWidth="sm" component="main" sx={{ pt: 6, pb: 4 }}>
      <Grid container spacing={4} alignItems="top">
        {holidays.map(day => <HolidayTile day={day} key={day.dateTime}></HolidayTile>)}
        </Grid>
    </Container>
  );
}
