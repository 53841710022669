import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { strava } from '../../lib/config'

export default function StravaSync() {

    const handleLogin = () => {
        const redirectUrl = window.location.origin + "/redirect"
        window.location = `http://www.strava.com/oauth/authorize?client_id=${strava.clientID}&response_type=code&redirect_uri=${redirectUrl}/exchange_token&approval_prompt=force&scope=${strava.scope}`;
    };

    return (
      <Paper sx={{ textAlign: 'center', mx:1, my:1, px:1, py:1}}>
        <Button variant="contained" type="submit" value="Submit" onClick={handleLogin} >Sync with Strava</Button>
      </Paper>
    );
}