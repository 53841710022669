import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { getLocations } from '../../services/apiService';
import LocationsUx from './LocationsUx'

export default function LocationsContainer() {
  const [days, setDays] = useState([]);
  const [year, setYear] = useState('2022');
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      getLocations(getAccessTokenSilently, year).then(setDays)
    }
  }, [isAuthenticated, getAccessTokenSilently, year]);

  return (
    isAuthenticated && (<LocationsUx days={days} year={year} setYear={setYear} />)
  );
}
