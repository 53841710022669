import { useEffect, useState } from 'react';
import { getToday } from '../services/apiService';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { useAuth0 } from '@auth0/auth0-react';
import {toHoursMins } from '../lib/timeUtils'
import SunIcon from '@mui/icons-material/WbSunny';
import MoonIcon from '@mui/icons-material/NightlightRound';
import TideIcon from '@mui/icons-material/Waves';
import WeatherIcon from '@mui/icons-material/Thermostat';


function StatusCard({ details: { title, icon, subHeader, good, content, lines } }) {
  return (
    <Card key={title}>
      <CardHeader
        title={title}
        action={icon}
        subheader={subHeader}
        titleTypographyProps={{ align: 'center' }}
        subheaderTypographyProps={{
          align: 'center',
        }}
        sx={{
          backgroundColor: (theme) => {
            return theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[700];
          },
        }}
      />
      <CardContent
        sx={{
          color: (theme) => {
            if (good) {
              return theme.palette.success.main;
            }
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'baseline',
            mb: 2,
          }}
        >
          <Typography component="h2" variant="h3" align="center">
            {content}
          </Typography>
        </Box>
        <ul>
          {lines.map((line) => (
            <Typography
              component="li"
              variant="subtitle1"
              align="center"
              key={line}
            >
              {line}
            </Typography>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
}

function toTideText(times) {
  return times.map((time) => `${time.type} ${time.time} (${time.height}m )`)
}

function buildTodayCards(today) {
  //  { title, icon, subHeader, good, content, lines }
  const sunCard = {
    xs: 12,
    sm: 12,
    md: 12,
    title: 'Sun',
    content: <SunIcon sx={{ fontSize: 80 }}/>,
    lines: [
      `Sun rise ${toHoursMins(today.sun.rise)}`,
      `Sun set ${toHoursMins(today.sun.set)}`,
      `Morning golden hour ends ${toHoursMins(today.sun.goldenHourEnd)}`,
      `Evening golden hour start ${toHoursMins(today.sun.goldenHour)}`,
    ]
  }
  const moonCard = {
    xs: 12,
    sm: 6,
    md: 6,
    title: 'Moon',
    content: <MoonIcon sx={{ fontSize: 80 }}/>,
    lines: [
      today.moon.moonPhase,
      today.moon.rise && `Moon rise ${toHoursMins(today.moon.rise)}`,
      today.moon.set && `Moon set ${toHoursMins(today.moon.set)}`,
    ]
  }
  const tideCard = {
    xs: 12,
    sm: 6,
    md: 6,
    title: 'Tides',
    content: <TideIcon sx={{ fontSize: 80 }}/>,
    lines: toTideText(today.tides),
  }
  const weatherCard = {
    xs: 12,
    sm: 12,
    md: 12,
    title: 'Weather',
    content: <WeatherIcon sx={{ fontSize: 80 }}/>,
    lines: [
      `${today.weather.main} (${today.weather.description})`,
      `Chance of rain ${Math.round(100 * today.weather.chanceOfRain)}%`,
      `Temperature ${Math.round(today.weather.temp.max)}°C`, 
      `max: ${Math.round(today.weather.temp.max)}°C, min: ${Math.round(today.weather.temp.min)}°C`,
      `Wind: ${Math.round(today.weather.wind.speed)}mph, max ${Math.round(today.weather.wind.speed + today.weather.wind.maxSpeed)} mph`
    ],
  }

  return [weatherCard, sunCard, moonCard, tideCard]
}

function EarthDetails({ today }) {
  const cardsInfo = buildTodayCards(today)
  return (
    <>
      {cardsInfo.map((cardInfo) => {
        return (

          <Grid
          item
          key={cardInfo.title}
          xs={cardInfo.xs}
          sm={cardInfo.sm}
          md={cardInfo.md}
          sx={{ alignItems: 'stretch' }}
          
        >
        <StatusCard details={cardInfo} />
        </Grid>

        )
      })}
    </>
  );
}


export default function Today(props) {
  const [today, setToday] = useState(undefined);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();


  useEffect(() => {
    if (isAuthenticated) {
      getToday(getAccessTokenSilently).then(setToday)
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  return (
    isAuthenticated && (
        <Container maxWidth="sm" component="main" sx={{ pt: 6, pb: 4, alignItems:"stretch" }}>
          <Grid container spacing={4} alignItems="stretch">
            {today && <EarthDetails today={today}></EarthDetails>}
          </Grid>
        </Container>
    )
  );
}