import React, { useEffect, useState } from 'react';
import { setCache } from '../../services/storageCache'
import DayCalendarUx from './DayCalendarUx';
import { useAuth0 } from '@auth0/auth0-react';

import { getDays } from '../../services/apiService';
import { LoadingBox } from '../structure/LoadingBox'

export default function DayCalendar() {
  const currYear = (new Date()).getFullYear()
  const [days, setDays] = useState([]);
  const [view, setView] = useState('health');
  const [year, setYear] = useState(String(currYear));
  const [milesFilter, setMilesFilter] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      console.log('year is ', year)
      setCache('days', undefined)
      setDays([])
      getDays(getAccessTokenSilently, year).then(setDays).then(() => setLoading(false))
    }
  }, [isAuthenticated, year, getAccessTokenSilently]);

  return (
    isAuthenticated && (
      loading ?
        <LoadingBox /> :
        <DayCalendarUx days={days} view={view} year={year} setView={setView} setYear={setYear} milesFilter={milesFilter} setMilesFilter={setMilesFilter} />
    )
  );
}
