import React from 'react';
import dateFormat from 'dateformat';
import Box from '@mui/material/Box';
import DryIcon from '@mui/icons-material/FavoriteTwoTone';
import DrinkIcon from '@mui/icons-material/LocalDrinkTwoTone';
import CelebrationIcon from '@mui/icons-material/Celebration';
import Typography from '@mui/material/Typography';

function borderStyle(activity, theme) {
  return activity.weekend ? `4px solid  ${theme.palette.divider}` : `4px solid  ${activity.dry ? theme.palette.success.light : theme.palette.primary.light}`
}

function getWeight(activity) {

  if (typeof activity.weight == "number") {
    return activity.weight.toFixed(1)
  }
  return activity.weight
}

function getActivityIcon(dry) {
  if (dry === undefined) {
    return <CelebrationIcon />
  }
  return dry === true ? <DryIcon /> : <DrinkIcon />  
}

export function HealthTile({ activity }) {
  activity.dateTitle = dateFormat(activity.date, 'dd/mm');
  activity.dow = dateFormat(activity.date, 'ddd');
  activity.weekend = activity.dow === 'Sat' || activity.dow === 'Sun';
  activity.weight = getWeight(activity)
  return (
    <Box
      key={activity.date}
      sx={{

        justifyContent: 'center',
        width: '3.7em',
        height: '5.2em',
        backgroundColor: (theme) => activity.dry
          ? theme.palette.success.light
          : theme.palette.primary.light,
        mx: 1,
        my: 1,
        borderBottom: (theme) => borderStyle(activity, theme),
        borderTop: (theme) => borderStyle(activity, theme),
        borderLeft: (theme) => borderStyle(activity, theme),
        borderRight: (theme) => borderStyle(activity, theme),
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      <Typography
        key={activity.date}
        textAlign="center"
        sx={{
          fontWeight: (theme) => activity.weekend
            ? theme.typography.fontWeightBold
            : theme.typography.fontWeightRegular,
        }}
      >
        <>{activity.dateTitle}</>
        { getActivityIcon(activity.dry) }
        <>{activity.weight}</>
      </Typography>
    </Box>
  )
}
